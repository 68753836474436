/* istanbul ignore file */

import { FlashbarProps } from '@amzn/awsui-components-react';
import { getString } from '../ui-string-labels/ui-strings-utils';
import React, { useState } from 'react';
import { Guid } from 'guid-typescript';

interface addFlashBarItemFunction {
  (content: string): void;
  (header: string, content: string | React.ReactNode): void;
}

export interface useFlashBarItemsActions {
  addSuccessFlashBarItem: addFlashBarItemFunction;
  addErrorFlashBarItem: addFlashBarItemFunction;
  addInfoFlashBarItem: addFlashBarItemFunction;
  addWarningFlashBarItem: addFlashBarItemFunction;
}

export interface useFlashBarItemsResult {
  flashBarItems: readonly FlashbarProps.MessageDefinition[];
  actions: useFlashBarItemsActions;
}

export function useFlashBarItems(): useFlashBarItemsResult {
  const [flashBarItems, setFlashBarItems] = useState<readonly FlashbarProps.MessageDefinition[]>([]);

  function addSuccessFlashBarItem(content: string): void;
  function addSuccessFlashBarItem(header: string, content: string | React.ReactNode): void;
  function addSuccessFlashBarItem(header?: string, content?: string | React.ReactNode) {
    addFlashBarItem('success', content, header);
  }

  function addErrorFlashBarItem(content: string): void;
  function addErrorFlashBarItem(header: string, content: string | React.ReactNode): void;
  function addErrorFlashBarItem(header?: string, content?: string | React.ReactNode) {
    addFlashBarItem('error', content, header);
  }

  function addInfoFlashBarItem(content: string): void;
  function addInfoFlashBarItem(header: string, content: string | React.ReactNode): void;
  function addInfoFlashBarItem(header?: string, content?: string | React.ReactNode) {
    addFlashBarItem("info", content, header);
  }

  function addWarningFlashBarItem(content: string): void;
  function addWarningFlashBarItem(header: string, content: string | React.ReactNode): void;
  function addWarningFlashBarItem(header?: string, content?: string | React.ReactNode) {
    addFlashBarItem("warning", content, header);
  }

  const addFlashBarItem = (type: FlashbarProps.Type, content?: string | React.ReactNode, header?: string) => {
    const id = Guid.create().toString();
    setFlashBarItems((prev: readonly FlashbarProps.MessageDefinition[]) => {
      return [
        ...prev,
        {
          type,
          content,
          header,
          dismissible: true,
          dismissLabel: getString('common.flashBar.dismissLabel'),
          onDismiss: () => setFlashBarItems(items => items.filter(item => item.id !== id)),
          id
        }
      ];
    });
  };
  return { flashBarItems, actions: { addSuccessFlashBarItem, addErrorFlashBarItem, addInfoFlashBarItem, addWarningFlashBarItem } };
}
